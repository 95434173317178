
import { RouteView } from "@/layouts";

import goodsList from "@/views/goodsManage/goodsList.vue"
export default {
  path: "/cateManage",
  name: "",
  component: RouteView,
  meta: {
    title: "商品管理",
    isupperLevels:true
  },
  children: [
    {
      path: "/goodsManage/list",
      name: "goodsManage",
      // component: cateList,
      component: goodsList,
      meta: {
        title: "商品",
        inMenu: false,
        isupperLevels:true
      },
    },
  ],
};
