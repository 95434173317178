// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 新增 POST /admin/api-v1/goods-brand-dcs */
export async function save(
  body: Manage.BrandDcsSaveReq,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>("/admin/api-v1/goods-brand-dcs", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 PUT /admin/api-v1/goods-brand-dcs/${param0} */
export async function update(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsBrandDcsUpdateParams,
  body: Manage.BrandDcsUpdateReq,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/goods-brand-dcs/${param0}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 删除 DELETE /admin/api-v1/goods-brand-dcs/${param0} */
export async function deleteUsingDelete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsBrandDcsDeleteParams,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/goods-brand-dcs/${param0}`, {
    method: "DELETE",
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 上下架 PUT /admin/api-v1/goods-brand-dcs/${param0}/up-down */
export async function upDown(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsBrandDcsUpDownParams,
  body: Manage.BrandDcsUpDownReq,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/goods-brand-dcs/${param0}/up-down`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 取消授权商户 POST /admin/api-v1/goods-brand-dcs/authorized-cancel/${param0} */
export async function authorizedCancel(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsBrandDcsAuthorizedCancelParams,
  body: number[],
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/goods-brand-dcs/authorized-cancel/${param0}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 授权-未授权-列表 GET /admin/api-v1/goods-brand-dcs/authorized-list */
export async function authorizedList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsBrandDcsAuthorizedListParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RCollectionBrandAuthorizedListResponse>(
    "/admin/api-v1/goods-brand-dcs/authorized-list",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** 已授权商户分页 GET /admin/api-v1/goods-brand-dcs/authorized-merchant */
export async function authorizedMerchantPage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsBrandDcsAuthorizedMerchantPageParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RIPageGoodsAuthorizedMerchantResponse>(
    "/admin/api-v1/goods-brand-dcs/authorized-merchant",
    {
      method: "GET",
      params: {
        // pageNo has a default value: 1
        pageNo: "1",
        // pageSize has a default value: 20
        pageSize: "20",
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** 已授权分页 GET /admin/api-v1/goods-brand-dcs/authorized-page */
export async function authorizedBrandPage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsBrandDcsAuthorizedBrandPageParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RIPageGoodsBrandAuthorizedResponse>(
    "/admin/api-v1/goods-brand-dcs/authorized-page",
    {
      method: "GET",
      params: {
        // pageNo has a default value: 1
        pageNo: "1",
        // pageSize has a default value: 20
        pageSize: "20",
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** 授权商户 POST /admin/api-v1/goods-brand-dcs/authorized/${param0} */
export async function authorizedMerchant(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsBrandDcsAuthorizedMerchantParams,
  body: Manage.PutGoodsBrandAuthorizedDiscountRequest[],
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/goods-brand-dcs/authorized/${param0}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 列表 GET /admin/api-v1/goods-brand-dcs/list */
export async function brands(options?: { [key: string]: any }) {
  return request<Manage.RCollectionGoodsBrandListResp>(
    "/admin/api-v1/goods-brand-dcs/list",
    {
      method: "GET",
      ...(options || {}),
    }
  );
}

/** 分页列表 GET /admin/api-v1/goods-brand-dcs/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsBrandDcsPageParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RIPageGoodsBranDcsPageResp>(
    "/admin/api-v1/goods-brand-dcs/page",
    {
      method: "GET",
      params: {
        // pageNo has a default value: 1
        pageNo: "1",
        // pageSize has a default value: 20
        pageSize: "20",
        ...params,
      },
      ...(options || {}),
    }
  );
}
