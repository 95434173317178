// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 获取文件上传地址 GET /api-v1/common/aliyun/oss/upload-url */
export async function getUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.AliyunOssGetUploadUrlParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RPresignedUrlResponse>(
    "/api-v1/common/aliyun/oss/upload-url",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}
