// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 发货-查询物流轨迹 GET /admin/api-v1/dcs-order/${param0}/logistic-track */
export async function getLogisticTrack(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.DcsOrderGetLogisticTrackParams,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.ROrderDeliveryLogisticTrackResponse>(
    `/admin/api-v1/dcs-order/${param0}/logistic-track`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 修改商品单价 POST /admin/api-v1/dcs-order/${param0}/price */
export async function updatePrice(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.DcsOrderUpdatePriceParams,
  body: Manage.DcsOrderUpdatePriceRequest,
  options?: { [key: string]: any }
) {
  const { orderId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/dcs-order/${param0}/price`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 确认收款 POST /admin/api-v1/dcs-order/confirm-payment */
export async function confirmPayment(
  body: Manage.DcsOrderConfirmPaymentRequest,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>("/admin/api-v1/dcs-order/confirm-payment", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 发货规格列表 GET /admin/api-v1/dcs-order/delivery-spec-list/${param0} */
export async function deliveryGoodsList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.DcsOrderDeliveryGoodsListParams,
  options?: { [key: string]: any }
) {
  const { dcsOrderId: param0, ...queryParams } = params;
  return request<Manage.RListDcsOrderDeliveryGoodsResponse>(
    `/admin/api-v1/dcs-order/delivery-spec-list/${param0}`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 详情 GET /admin/api-v1/dcs-order/details/${param0} */
export async function details(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.DcsOrderDetailsParams,
  options?: { [key: string]: any }
) {
  const { dcsOrderId: param0, ...queryParams } = params;
  return request<Manage.RDcsOrderDetailsResponse>(
    `/admin/api-v1/dcs-order/details/${param0}`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 分页列表 GET /admin/api-v1/dcs-order/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.DcsOrderPageParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RIPageDcsOrderPageResponse>(
    "/admin/api-v1/dcs-order/page",
    {
      method: "GET",
      params: {
        // pageNo has a default value: 1
        pageNo: "1",
        // pageSize has a default value: 20
        pageSize: "20",
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** 发货 POST /admin/api-v1/dcs-order/upload-url/delivery */
export async function delivery(
  body: Manage.DcsOrderDeliveryRequest,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>("/admin/api-v1/dcs-order/upload-url/delivery", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 确认收款凭证上传地址 GET /admin/api-v1/dcs-order/upload-url/payment-voucher */
export async function getPaymentVoucherUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.DcsOrderGetPaymentVoucherUploadUrlParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RPresignedUrlResponse>(
    "/admin/api-v1/dcs-order/upload-url/payment-voucher",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}
