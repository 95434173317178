import { invert } from 'lodash-es'
import { AxiosResponse } from 'axios'

import request from "@/request/request";

export const imageMimeTypeMap = {
  'image/jpeg': ['jpg', 'jpeg', 'jfif'],
  'image/png': ['png'],
  'image/gif': ['gif'],
  'image/bmp': ['bmp'],
  'image/vnd.ms-dpg': ['dpg'],
  'image/webp': ['webp'],
  'image/svg+xml': ['svg'],
  'image/x-icon': ['ico']
}
export const imageMimeTypes = Object.keys(imageMimeTypeMap)
export const imageExt = Object.values(imageMimeTypeMap).flat()
export const videoMimeTypeMap = {
  'video/mp4': ['mp4'],
  'video/quicktime': ['mov'],
  'video/mpeg': ['mpeg'],
  'video/ogg': ['ogv'],
  'video/webm': ['webm'],
  'video/x-msvideo': ['avi'],
  'video/x-flv': ['flv'], // Flash Video
  'video/3gpp': ['3gp'], // 3GP Video
  'video/3gpp2': ['3g2'], // 3G2 Video
  'video/x-matroska': ['mkv'], // Matroska Video
  'video/x-ms-wmv': ['wmv'], // Windows Media Video
  'video/x-ms-asf': ['asf'], // Advanced Systems Format
  'video/x-m4v': ['m4v'], // MPEG-4 Video
  'video/h264': ['h264'], // H.264 Video
  'video/h265': ['h265'], // H.265 Video
  'video/mp2t': ['ts'], // MPEG Transport Stream
  'application/vnd.rn-realmedia': ['rm'], // RealMedia
  'application/x-mpegURL': ['m3u8'] // HLS Streaming Playlist
}
export const videoMimeTypes = Object.keys(videoMimeTypeMap)
export const videoExt = Object.values(videoMimeTypeMap).flat()
export const excelMimeTypeMap = {
  'application/vnd.ms-excel': ['xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['xlsx']
}
export const excelMimeTypes = Object.keys(excelMimeTypeMap)
export const excelExt = Object.values(excelMimeTypeMap).flat()
export const mimeTypeMap: { [key: string]: string[] } = {
  // 图像类型
  ...imageMimeTypeMap,
  ...videoMimeTypeMap,
  ...excelMimeTypeMap,
  // 文本类型
  'text/plain': ['txt'],
  'text/html': ['html'],
  'text/css': ['css'],
  'text/javascript': ['js'],
  'application/javascript': ['js'],
  'text/xml': ['xml'],
  'application/xml': ['xml'],
  'text/csv': ['csv'],
  'text/markdown': ['md'],
  // JSON
  'application/json': ['json'],
  'audio/mpeg': ['mp3'],
  'audio/wav': ['wav'],
  'audio/ogg': ['ogg'],
  'audio/webm': ['weba'],
  'audio/aac': ['aac'],
  'audio/midi': ['midi'],
  'application/octet-stream': ['bin'],
  'application/pdf': ['pdf'],
  'application/zip': ['zip'],
  'application/x-rar-compressed': ['rar'],
  'application/msword': ['doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['docx'],
  'application/vnd.ms-powerpoint': ['ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['pptx'],
  'application/x-www-form-urlencoded': ['url'],
  'application/x-7z-compressed': ['7z'],
  'application/x-tar': ['tar'],
  'application/x-bzip': ['bz'],
  'application/x-bzip2': ['bz2'],
  'application/x-java-archive': ['jar'],
  'font/woff': ['woff'],
  'font/woff2': ['woff2'],
  'application/x-font-ttf': ['ttf'],
  'application/x-font-opentype': ['otf'],
  'application/x-shockwave-flash': ['swf'],
  'application/x-msdownload': ['exe'],
  'application/rtf': ['rtf'],
  'application/vnd.oasis.opendocument.text': ['odt'],
  'application/vnd.oasis.opendocument.spreadsheet': ['ods'],
  'application/vnd.oasis.opendocument.presentation': ['odp'],
  'message/rfc822': ['eml']
}
/**
 * 是否是图片
 * @param file
 */
export const isImage = (file?: Blob): boolean => {
  if (!file) return false
  return imageMimeTypes.includes(file.type)
}
export const isImageMime = (mime?: string): boolean => {
  if (!mime) return false
  return imageMimeTypes.includes(mime)
}
/**
 * 是否是视频后缀
 */
export const isImageExt = (url?: string): boolean => {
  if (!url) return false
  const lowerCaseUrl = url.toLowerCase()
  if (imageExt.find(ext => lowerCaseUrl.endsWith(ext))) return true
  const mime = getBase64Mime(url)
  return !!mime && isImageMime(mime)
}
/**
 * 是否是视频
 * @param file file
 */
export const isVideo = (file?: Blob): boolean => {
  if (!file) return false
  return videoMimeTypes.includes(file.type)
}
/**
 * 是否是视频后缀
 */
export const isVideoExt = (url?: string): boolean => {
  if (!url) return false
  const lowerCaseUrl = url.toLowerCase()
  return !!videoExt.find(ext => lowerCaseUrl.endsWith(ext))
}
/**
 * 获取文件后缀
 * @param blob blob
 */
export const getBlobExt = function getBlobExt(blob: Blob): string {
  if (blob instanceof File) {
    // 尝试从文件名中获取后缀
    const ext = getFileExt(blob)
    if (ext) return ext
  }
  // 如果没有文件名或无法获取后缀，尝试从 MIME 类型中获取
  return getMimeExt(blob.type)
}
/**
 * 获取base64的mime
 * @param base64String
 */
export const getBase64Mime = (base64String?: string): string | undefined => {
  if (!base64String) return undefined
  // 正则表达式匹配 Base64 图片前缀中的 MIME 类型部分
  const mimeRegex = /^data:([a-zA-Z0-9/+.-]+);base64,/
  const match = base64String.match(mimeRegex)
  // 如果匹配成功，返回 MIME 类型，否则返回 null
  return match ? match[1] : undefined
}
/**
 * 获取文件后缀
 * @param file file
 */
export const getFileExt = (file?: File): string => {
  return getUrlExt(file?.name)
}
export const getUrlExt = (url?: string): string => {
  if (!url) return ''
  const lastDotIndex = url.lastIndexOf('.')
  // 返回后缀并转换为小写
  return url.substring(lastDotIndex + 1).toLowerCase()
}

/**
 * 获取文件后缀
 * @param mimeType mimeType
 */
export function getMimeExt(mimeType: string): string {
  const [ext] = mimeTypeMap[mimeType]
  return ext || ''
}

/**
 * 获取url的mime类型
 * @param url url
 */
export function getUrlMimeType(url?: string): string {
  const typeMimeMap = invert(mimeTypeMap)
  const ext = getUrlExt(url)
  return typeMimeMap[ext]
}

/**
 * blob转化为File
 * @param blob blob
 * @param fileName 文件名称
 */
export const toFile = (blob: Blob | File, fileName?: string): File => {
  if (blob instanceof File) return blob
  const date = Date.now()
  const genFileName = fileName || `${date}.${getBlobExt(blob)}`
  return new File([blob], genFileName, {
    type: blob.type,
    lastModified: Date.now()
  })
}
/**
 * 将文件转换为Base64
 * @param file file
 */
export const toBase64 = (file: Blob | File): Promise<FileReader['result']> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
/**
 * 从响应头内容中获取文件名
 * @param contentDisposition 内容
 */
export const getFilenameFromContentDisposition = (contentDisposition?: string): string | undefined => {
  if (!contentDisposition) return undefined
  // 匹配文件名
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(contentDisposition)
  if (matches && matches[1]) {
    // 解码
    return decodeURIComponent(matches[1].replace(/['"]/g, ''))
  }
  return undefined
}
export const download = async (downloadUrl: string, config?: any) => {
  const response: AxiosResponse = await request(downloadUrl, {
    responseType: 'blob',
    ...config
  })
  // 获取文件名
  const filename = getFilenameFromContentDisposition(response.headers['content-disposition'])
  if (!filename) throw new Error('无法从响应头[content-disposition]获取文件名')
  if ('showSaveFilePicker' in window) {
    await pickerDownload(response.data as Blob, filename)
  } else {
    await fallbackDownload(response.data as Blob, filename)
  }
}
/**
 * 使用showSaveFilePicker，使用 createObjectURL 和 <a> 标签
 * @param blob 文件
 * @param filename 文件名
 */
export const pickerDownload = async (blob: Blob, filename: string) => {
  let writableStream: any | undefined = undefined
  try {
    const file = toFile(blob, filename)
    // 使用 showSaveFilePicker 保存文件
    const mimeType = file.type || 'application/octet-stream'
    const extension = '.' + getMimeExt(mimeType)
    // 打开文件保存对话框
    const fileHandle = await (window as any).showSaveFilePicker({
      suggestedName: filename,
      types: [
        {
          description: 'All Files',
          accept: {
            [mimeType]: [extension]
          }
        }
      ]
    })
    // 创建可写流
    writableStream = await fileHandle.createWritable()
    // 将blob写入流
    await writableStream?.write(file)
  } finally {
    // 关闭流，完成保存
    writableStream && (await writableStream.close())
  }
}
/**
 * 回退下载方法，使用 createObjectURL 和 <a> 标签
 * @param blob 文件
 * @param filename 文件名
 */
export const fallbackDownload = async (blob: Blob, filename: string) => {
  // 创建一个 URL 对象链接到 blob
  let url: string | undefined = undefined
  // 创建一个 <a> 标签
  let a: HTMLAnchorElement | undefined = undefined
  try {
    // 创建一个 URL 对象链接到 blob
    url = window.URL.createObjectURL(blob)
    // 创建一个 <a> 标签
    a = document.createElement('a')
    a.href = url
    a.download = filename
    // 将 <a> 标签添加到 DOM 并触发点击
    document.body.appendChild(a)
    a.click()
  } finally {
    // 移除 <a> 标签并释放 URL 对象
    a && document.body.removeChild(a)
    url && window.URL.revokeObjectURL(url)
  }
}



export const prefixMap = {
  // 营业执照
  companyLicense: {
    prefix: 'company/license',
    objectAcl: 'private'
  },
  // 用户头像
  userAvatar: {
    prefix: 'user/avatar',
    objectAcl: 'public-read'
  },
  // 危化品证书
  dangerous: {
    prefix: 'company/dangerous',
    objectAcl: 'public-read'
  },
  //公司logo
  logo: {
    prefix: 'company/logo',
    objectAcl: 'public-read'
  },
  //公司二维码
  companyQrcode: {
    prefix: 'company/qrcode',
    objectAcl: 'public-read'
  },
  //公司介绍
  companyIntroduce: {
    prefix: 'company/introduce',
    objectAcl: 'public-read'
  },
  //公司介绍
  companyVideo: {
    prefix: 'company/introduce-video',
    objectAcl: 'public-read'
  },
  // 商品图
  goods: {
    prefix: 'goods',
    objectAcl: 'public-read'
  },
  // 名片
  profile: {
    prefix: 'merchant/profile',
    objectAcl: 'public-read'
  },
  //发票
  invoice: {
    prefix: 'order/invoice',
    objectAcl: 'private'
  },
  //收款凭证
  receiptVoucher: {
    prefix: 'order/receipt-voucher',
    objectAcl: 'private'
  },
  //退款凭证
  refundVoucher: {
    prefix: 'order/pay-flow-voucher',
    objectAcl: 'private'
  },
  //付款凭证
  paymentVoucher: {
    prefix: 'order/payment-voucher',
    objectAcl: 'private'
  },
}

export function getFullOssUrl(key: string) {
  const env = process.env;

  const imgPrefix = env.VUE_APP_IMG_PREFIX || ''

  if (key?.startsWith('http')) {

    return key
  }
  return imgPrefix.replaceAll(/\b$/g, '/') + key?.replaceAll(/^\//g, '')
}

export function analyzeUrl(url: string) {
  const env = process.env;
  const imgPrefix = env.VUE_APP_IMG_PREFIX || ''
  if (!url) {
    return
  }
  const matcher = /^((https?):\/\/[\w-]+(\.[\w-]+)+)?\/?(?<path>([\w-]+\/)*([\w-]+\.\w+))(\?.+)?$/.exec(url) as any
  let path = url,
    fullPath = url
  if (matcher) {
    path = matcher?.groups?.path
  }
  if (!url.startsWith('http')) {
    fullPath = imgPrefix.replaceAll(/(\/|\b)$/g, '/') + path
  }
  return { path, fullPath }
}